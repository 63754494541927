import { FIELD_NAMES } from 'constants/headerTableNames';

import convertAndSaveAsExcelFile, { ISettings } from 'util/convertAndSaveAsExcelFile';

import { toLocale } from 'util/dates';

import Button, { ButtonProps } from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

import { useGridApiContext } from '@mui/x-data-grid-pro';

import { useAppSelector } from 'store';
import { selectContragentsData } from 'store/selectors';
import { IContragent } from 'types';

export interface IButtonsToolbarProps extends Pick<ButtonProps, 'onClick' | 'color'> {
  name: string;
  Icon: JSX.Element | null;
}

export default function ButtonsToolbar() {
  const apiRef = useGridApiContext();
  const selectedRow = apiRef.current.getSelectedRows();
  const data = useAppSelector(selectContragentsData);
  const columns = apiRef.current.getAllColumns();

  const formatedDate = data.date && toLocale(data.date);

  const exportToCsv = (): void => {
    apiRef.current.exportDataAsCsv({
      delimiter: ';',
      utf8WithBom: true,
      fileName: `Контрагенты_${formatedDate}`,
    });
  };

  const exportToExcel = (): void => {
    const selectedEntities/*: IContragent[]*/: any = Array.from(selectedRow.values());
    const columnsWidth = columns.map((elem) => ({ wpx: elem.width || 20 })).slice(1);

    const settings: ISettings = {
      extension: '.xlsx',
      fileName: `Контрагенты_${formatedDate}`,
      sheetName: 'Таблица 1',
      columnsWidth: columnsWidth,
    };

    const callback = (entity: IContragent) => ({
      [FIELD_NAMES.ID]: entity.id,
      [FIELD_NAMES.INN]: entity.inn,
      [FIELD_NAMES.ORGNAME]: entity.orgName,
      [FIELD_NAMES.EGRULSTATUSTE]: entity.egrulStatusTe,
      [FIELD_NAMES.EGRULINFO]: entity.egrulInfo,
      [FIELD_NAMES.KODEKS1]: entity.kodeks1,
      [FIELD_NAMES.KODEKS2]: entity.kodeks2,
      [FIELD_NAMES.NORMACS]: entity.normaCS,
      [FIELD_NAMES.EXPRDATE]: entity.exprDate,
      [FIELD_NAMES.DISTRIBUTERTE]: entity.distributerTe,
      [FIELD_NAMES.ISUPBSTATUS]: entity.isupbStatus,
      [FIELD_NAMES.ISUPBINFO]: entity.isupbInfo,
      [FIELD_NAMES.HOLDING]: entity.holding,
    });

    if (selectedEntities.length) {
      convertAndSaveAsExcelFile(selectedEntities, callback, settings);
    } else {
      convertAndSaveAsExcelFile(data.value, callback, settings);
    }
  };

  const buttons: IButtonsToolbarProps[] = [
    {
      name: 'Выгрузить CSV',
      Icon: <DownloadIcon />,
      color: 'primary',
      onClick: exportToCsv,
    },
    {
      name: 'Выгрузить Excel',
      Icon: null,
      color: 'primary',
      onClick: exportToExcel,
    },
  ];

  return (
    <>
      {buttons.map(({ name, Icon, color, onClick }) => (
        <Button key={name} onClick={onClick} color={color} startIcon={Icon}>
          {name}
        </Button>
      ))}
    </>
  );
}
