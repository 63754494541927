import { ChangeEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import ChipBox from 'styled/ChipBox';

interface IChipInputProps {
  data: string[];
  remove: (item: string) => void;
  add: (data: string[]) => void;
}

export default function ChipInput(props: IChipInputProps): JSX.Element {
  const { data, remove, add } = props;
  const [inputValue, setInputValue] = useState('');
  const [newData, setNewData] = useState<string[]>([]);
  const keysCode = [13, 32, 188, 186, 9];

  useEffect(() => {
    setNewData(data);
  }, [data]);

  const handleKeyDown = (e: any): void => {
    if (e.key === 'Tab') {
      e.preventDefault();
    }
    if (keysCode.includes(e.keyCode)) {
      const newSelectedItem = [...newData];

      if (e.target.value && e.target.value.trim().length >= 9) {
        newSelectedItem.push(e.target.value);
        const formatedValue = newSelectedItem.map((str) => {
          if (str.length === 9) {
            return '0' + str;
          }
          return str;
        });
        add(formatedValue);
        setInputValue('');
      }
    }

    if (newData.length && !inputValue.length && e.key === 'Backspace') {
      add(newData.slice(0, data.length - 1));
      setInputValue('');
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;

    if (value.length >= 12) {
      const arrayinn = value
        .split(/[\s.,]/g)
        .filter(Number)
        .filter((elem) => elem.length >= 9)
        .map((str) => {
          if (str.length === 9) {
            return '0' + str;
          }
          return str;
        });
      add([...newData, ...arrayinn]);
      setInputValue('');
      return;
    }

    if (Number(value) || value === '' || value === '0') {
      setInputValue(value);
    }
  };

  const handleDeleteItem = (item: string) => (): void => remove(item);

  return (
    <ChipBox>
      <TextField
        variant='filled'
        value={inputValue}
        placeholder={newData.length ? '' : 'Список ИНН'}
        fullWidth
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: newData.map((item, index) => (
            <Chip key={item + index} label={item} onDelete={handleDeleteItem(item)} />
          )),
          disableUnderline: true,
          'aria-label': 'field',
        }}
        inputProps={{
          'aria-label': 'input',
        }}
      />
    </ChipBox>
  );
}
