import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InnState } from 'types';

const initialState: InnState = {
  data: [],
};

const innSlice = createSlice({
  name: 'inn',
  initialState,
  reducers: {
    addInn: (state, action: PayloadAction<string[]>) => {
      state.data = action.payload;
    },
    removeInn: (state, action: PayloadAction<string>) => {
      state.data.splice(state.data.indexOf(action.payload), 1);
    },
    removeAllInn: () =>  initialState,
  },
});
export const { addInn, removeInn, removeAllInn } = innSlice.actions;
export default innSlice.reducer;
